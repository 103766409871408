import { useTheme } from 'styled-components'
import React from 'react'

export default function ShowIcon (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.primary.value
  return (
    <svg width="16" height="16" viewBox="0 0 48 48" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path d="M24 13C31.58 13 38.34 17.26 41.64 24C38.34 30.74 31.58 35 24 35C16.42 35 9.66 30.74 6.36 24C9.66 17.26 16.42 13 24 13ZM24 9C14 9 5.46 15.22 2 24C5.46 32.78 14 39 24 39C34 39 42.54 32.78 46 24C42.54 15.22 34 9 24 9ZM24 19C26.76 19 29 21.24 29 24C29 26.76 26.76 29 24 29C21.24 29 19 26.76 19 24C19 21.24 21.24 19 24 19ZM24 15C19.04 15 15 19.04 15 24C15 28.96 19.04 33 24 33C28.96 33 33 28.96 33 24C33 19.04 28.96 15 24 15Z" fill={svgColour}/>
    </svg>
  )
}