import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import styled from 'styled-components'
import { MenuArrowDown } from '@ig-caa/media'
import { FlagIcon } from 'react-flag-kit'
import { CountryFilterType, Theme } from '../AppProps'

interface MarketFilterType {
  apiQuery: string
  setApiQuery: Dispatch<SetStateAction<string>>
  setData: Dispatch<SetStateAction<any[] | null>>
  setPage: Dispatch<SetStateAction<number>>
  markets: CountryFilterType
  setMarkets: Dispatch<SetStateAction<CountryFilterType>>
  theme: Theme
}

export default function FilterByMarket ({ apiQuery, setApiQuery, setData, setPage, markets, setMarkets, theme }: MarketFilterType) {
  const checkboxColor = (theme === 'Light') ? 'black' : ''

  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleMarketSelection = (market: string) => {
    if (markets[market].checked) {
      setApiQuery(apiQuery.replace(`${markets[market].apiString}`, ''))
    } else {
      setApiQuery(apiQuery.concat(`${markets[market].apiString}`))
    }
    /* istanbul ignore next */
    setMarkets((prevState) => ({
      ...prevState,
      [market]: {
        ...prevState[market],
        checked: !prevState[market].checked
      }
    }))
    setData([])
    setPage(1)
  }

  const categoriesMenu = useRef(null)
  const filterButton = useRef(null)

  const closeCategoriesMenu = (e: any) => {
    // @ts-expect-error
    if (isOpen && !categoriesMenu.current?.contains(e.target) && !filterButton.current?.contains(e.target)) {
      setIsOpen(false)
    }
  }

  document.addEventListener('mousedown', closeCategoriesMenu)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} >
      <FilterButtonContainer data-testid={'market-filter-container'} onClick={handleClick} ref={filterButton}>
        <FilterButtonText>Select Region</FilterButtonText><MenuArrowDown/>
      </FilterButtonContainer>
      {isOpen && (
        <CategoriesContainer data-testid={'market-categories-container'} ref={categoriesMenu}>
          {Object.keys(markets).map((m, i) => (
            <Category onClick={() => handleMarketSelection(m)} key={i}>
              <CategoryCheckbox
                data-testid={`${markets[m].countryFlag}-checkbox`}
                type="checkbox"
                name={markets[m].countryFlag}
                onChange={() => {}}
                checked={markets[m].checked}
                checkboxColor={checkboxColor}
              />
              <CategoryText>
                <FlagIcon code={markets[m].countryFlag} size={15} width={21} />
              </CategoryText>
              <div>
                {markets[m].countryName}
              </div>
            </Category>
          )
          )}
        </CategoriesContainer>
      )}
    </div>

  )
}

const FilterButtonContainer = styled.div<{
  isOpen?: boolean
}>`
  width: 100px;
  height: 14px;
  padding: 9px 12px 9px 12px;
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  box-sizing: unset;
`

const FilterButtonText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const CategoriesContainer = styled.div`
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #00295426;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  position: absolute;
  z-index: 13;
  margin-top: 36px;
  min-width: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Category = styled.div`
  padding: 4px 16px 4px 8px;
  gap: 6px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 166px;

  &:hover {
    background: ${({ theme }) => theme.color.separator.primary.background.value};
    border-radius: 8px;
  }
  box-sizing: unset;
`

const CategoryCheckbox = styled.input<{
  checkboxColor: string
}>`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.dropdown.border.value};
  border-radius: 2px;
  display: flex !important;
  margin: 0px;
  cursor: pointer;
  accent-color: ${({ checkboxColor }) => checkboxColor};
`

const CategoryText = styled.label`
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: -5px;
  cursor: pointer;
`
