import { ENVIRONMENTS } from '../components/AppProps'

const ENDPOINTS = {
  prod: 'https://tipranks-api.ig.com/tipranks/api/stocks-screener?',
  test: 'https://net-tipranks-api.ig.com/tipranks/api/stocks-screener?',
  uat: 'https://web-tipranks-api.ig.com/tipranks/api/stocks-screener?',
  demo: 'https://demo-tipranks-api.ig.com/tipranks/api/stocks-screener?',
  local: 'https://net-tipranks-api.ig.com/tipranks/api/stocks-screener?'
}

export default async function fetchStockScreener (env: keyof typeof ENVIRONMENTS, cst: string, apiQuery: string, page: number, signal: AbortSignal): Promise<any> {
  return await fetch(`${ENDPOINTS[env]}${apiQuery}&page=${page}`, {
    headers: {
      cst
    },
    signal
  })
}
