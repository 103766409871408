import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import styled from 'styled-components'
import { ColumnFilterType, FilterByColumnStateObjType } from './FilterByColumnStateObj'
import { MenuArrowDown } from '@ig-caa/media'
import { Theme } from '../AppProps'

interface FilterByColumnType {
  filter: { displayName: string, filters: ColumnFilterType[], keyName: string }
  apiQuery: string
  setApiQuery: Dispatch<SetStateAction<string>>
  setData: Dispatch<SetStateAction<any[] | null>>
  setPage: Dispatch<SetStateAction<number>>
  theme: Theme
  activeFilters: FilterByColumnStateObjType
  appWidth: number
  setActiveFilterCount: Dispatch<SetStateAction<number>>
}

export default function FilterByColumn ({ filter, apiQuery, setApiQuery, setData, setPage, theme, activeFilters, appWidth, setActiveFilterCount }: FilterByColumnType) {
  const [isOpen, setIsOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  /* istanbul ignore next */
  const checkboxColor = (theme === 'Light') ? 'black' : ''

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const toggleFilter = (e: React.MouseEvent) => {
    const selectedFilter = e.currentTarget.textContent
    const selectedFilterInStateObject = activeFilters[filter.keyName].filters.find((a: { categoryName: string }) => selectedFilter === a.categoryName)
    setData([])
    setPage(1)
    /* istanbul ignore next */
    if (!selectedFilterInStateObject) return
    selectedFilterInStateObject.checked = !selectedFilterInStateObject.checked
    if (selectedFilterInStateObject.checked) {
      setApiQuery(apiQuery + `&${activeFilters[filter.keyName].keyName}=${selectedFilterInStateObject?.categoryValue}`)
      setActiveFilterCount((prevState) => prevState + 1)
    } else {
      setApiQuery(apiQuery.replace(`&${activeFilters[filter.keyName].keyName}=${selectedFilterInStateObject?.categoryValue}`, ''))
      setActiveFilterCount((prevState) => prevState - 1)
    }
    setIsChecked(!isChecked)
  }

  const categoriesMenu = useRef(null)
  const filterButton = useRef(null)

  const closeCategoriesMenu = (e: any) => {
    // @ts-expect-error
    if (isOpen && !categoriesMenu.current?.contains(e.target) && !filterButton.current?.contains(e.target)) {
      setIsOpen(false)
    }
  }

  document.addEventListener('mousedown', closeCategoriesMenu)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FilterButtonContainer
        data-testid={`column-filter-button-container-${filter?.displayName}`}
        onClick={handleClick}
        ref={filterButton}
        title={filter?.displayName}
        appWidth={appWidth}
      >
        <FilterButtonText>{filter?.displayName}</FilterButtonText><MenuArrowDown/>
      </FilterButtonContainer>
      {isOpen && (
        <CategoriesContainer data-testid={'column-filter-categories-container'} ref={categoriesMenu} appWidth={appWidth}>
          {filter.filters.map((f, i) => (
            <Category onClick={toggleFilter} key={i}>
              <CategoryCheckbox
                data-testid={`${f.categoryName}-checkbox`}
                type="checkbox"
                name={f.categoryName}
                value={f.categoryName}
                onChange={() => {}}
                checked={activeFilters[filter.keyName].filters.find(a => a.categoryName === f.categoryName)?.checked}
                checkboxColor={checkboxColor}
              />
              <CategoryText>
                {f.categoryName}
              </CategoryText>
            </Category>
          )
          )}
        </CategoriesContainer>
      )}
    </div>
  )
}

const FilterButtonContainer = styled.div<{
  isOpen?: boolean
  appWidth: number
}>`
  width: ${({ appWidth }) => appWidth > 375 ? '154px' : appWidth > 300 ? '125px' : '115px'};
  height: 14px;
  padding: 9px 12px 9px 12px;
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  box-sizing: unset;
`

const FilterButtonText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const CategoriesContainer = styled.div<{
  appWidth: number
}>`
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #00295426;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  position: absolute;
  z-index: 13;
  margin-top: 36px;
  min-width: 178px;
`

const Category = styled.div`
  padding: 4px 16px 4px 8px;
  gap: 8px;
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.separator.primary.background.value};
    border-radius: 8px;
  }
  box-sizing: unset;
`

const CategoryCheckbox = styled.input<{
  checkboxColor: string
}>`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.dropdown.border.value};
  border-radius: 2px;
  display: flex !important;
  margin: 0px;
  cursor: pointer;
  accent-color: ${({ checkboxColor }) => checkboxColor};
`

const CategoryText = styled.label`
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0px;
  cursor: pointer;
`
