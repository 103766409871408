import { useTheme } from 'styled-components'
import React from 'react'

export default function HideIcon (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.primary.value
  return (
    <svg width="16" height="16" viewBox="0 0 48 48" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path d="M24 11.9496C31.58 11.9496 38.34 16.2096 41.64 22.9496C40.46 25.3896 38.8 27.4896 36.82 29.1896L39.64 32.0096C42.42 29.5496 44.62 26.4696 46 22.9496C42.54 14.1696 34 7.94961 24 7.94961C21.46 7.94961 19.02 8.34961 16.72 9.08961L20.02 12.3896C21.32 12.1296 22.64 11.9496 24 11.9496ZM21.86 14.2296L26 18.3696C27.14 18.8696 28.06 19.7896 28.56 20.9296L32.7 25.0696C32.86 24.3896 32.98 23.6696 32.98 22.9296C33 17.9696 28.96 13.9496 24 13.9496C23.26 13.9496 22.56 14.0496 21.86 14.2296ZM4.02 7.68961L9.38 13.0496C6.12 15.6096 3.54 19.0096 2 22.9496C5.46 31.7296 14 37.9496 24 37.9496C27.04 37.9496 29.96 37.3696 32.64 36.3096L39.48 43.1496L42.3 40.3296L6.84 4.84961L4.02 7.68961ZM19.02 22.6896L24.24 27.9096C24.16 27.9296 24.08 27.9496 24 27.9496C21.24 27.9496 19 25.7096 19 22.9496C19 22.8496 19.02 22.7896 19.02 22.6896ZM12.22 15.8896L15.72 19.3896C15.26 20.4896 15 21.6896 15 22.9496C15 27.9096 19.04 31.9496 24 31.9496C25.26 31.9496 26.46 31.6896 27.54 31.2296L29.5 33.1896C27.74 33.6696 25.9 33.9496 24 33.9496C16.42 33.9496 9.66 29.6896 6.36 22.9496C7.76 20.0896 9.8 17.7296 12.22 15.8896Z" fill={svgColour}/>
    </svg>
  )
}
