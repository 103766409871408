import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { initTranslation, I18nextProvider } from '@ig-caa/i18n'
import '../styles/App.css'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { ThemeSet, StyleProvider, Spinner } from 'ig-phoenix'
import { AppProps } from './AppProps'
import StockScreener from './StockScreener'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

export function App (props: AppProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <AppInner {...props} />
    </QueryClientProvider>
  )
}

function AppInner (props: AppProps): JSX.Element {
  const {
    theme,
    sessionData: { clientLocale: unformattedLocale }
  } = props

  const clientLocale = unformattedLocale.replace('_', '-')

  const supportedLanguages = ['en-GB', 'de-DE', 'fr-FR', 'it-IT', 'nl-NL', 'no-NO', 'pt-PT', 'sv-SE', 'es-ES']
  const loadTranslationsFn = async () => {
    const productTranslationFile = `${clientLocale}-${props.sessionData.webSiteId}`
    return await initTranslation(
      clientLocale,
      supportedLanguages,
      /* istanbul ignore next */
      async (locale: string) => await import(`../locales/${locale}.json`),
      props.sessionData.webSiteId,
      productTranslationFile)
  }

  const { isLoading, isError, error, data: i18n } = useQuery(['stockScreenerLoadTranslations', clientLocale], loadTranslationsFn)

  if (isLoading) {
    return (
      <div className="stock-screener-loading" data-testid='isLoading'>
        <StyleProvider data-testid='style-provider' colorScheme={theme} themeSet={ThemeSet.IGWTPRebrand} withFonts={false}>
          <Spinner radius='40px' />
        </StyleProvider>
      </div>
    )
  }

  if (isError) return <>An error has occurred: {(error as Error)?.message}</>

  return (
    <I18nextProvider i18n={i18n}>
      <StyleProvider data-testid='style-provider' colorScheme={theme} themeSet={ThemeSet.IGWTPRebrand}>
        <div className={'app-caa-stock-screener'} data-testid='app-caa-stock-screener'>
          <StockScreener {...props}/>
        </div>
      </StyleProvider>
    </I18nextProvider>
  )
}

const lifecycles = singleSpaReact({ React, ReactDOM, rootComponent: App })
export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles
