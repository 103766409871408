import React from 'react'
import styled from 'styled-components'

interface CircleProps {
  textColor: string
  bgColor: string
  score: number
  classname: string
}

export default function Circle ({ textColor, bgColor, score, classname }: CircleProps): JSX.Element {
  return (
    <SmallCircle data-testid={classname} bgColor={bgColor} textColor={textColor} className={classname}>
      {score}
    </SmallCircle>
  )
}

export const SmallCircle = styled.div<{
  bgColor: string
  textColor: string
}>`
  max-width: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  flex-grow: 1;
  border-radius: 50%;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
`
