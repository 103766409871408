import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

const LoadMore = ({ setPage }: { setPage: Dispatch<SetStateAction<number>> }) => {
  const getMoreData = () => {
    setPage((prevState) => prevState + 1)
  }

  return (
    <LinkContainer>
      <LinkComponent data-testid='load-more-link' onClick={getMoreData}>Load more stocks</LinkComponent>
    </LinkContainer>
  )
}

export default LoadMore

const LinkContainer = styled.div`
  display: flex;
`

const LinkComponent = styled.a`
  font-weight: 400;
  font-size: 13px;
  line-height: ${({ theme }) => theme.size.link.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.link.value};
  cursor: pointer;
  padding: 10px 0;
  text-decoration: underline;
  max-width: 120px;
`
