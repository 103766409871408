import styled from 'styled-components'
import Heading from './Heading'
import {
  marketNameTD,
  sectorTD,
  marketCapTD,
  analystConsensusTD,
  analystPriceTargetTD,
  newsSentimentTD,
  priceTD,
  avg3MVolumeTD,
  smartScoreTD,
  volumeTD,
  peRatioTD,
  dividendYieldTD,
  bestAnalystConsensusTD,
  bestAnalystPriceTargetTD,
  insiderSignalTD,
  bloggerConsensusTD,
  investorSentimentTD,
  hedgeFundSignalTD,
  mediaBuzzTD,
  fiftyTwoWeekTD,
  earningsDateTD,
  exDivDateTD,
  pSRatioTD,
  pFCFRatioTD,
  oneDayPerfTD,
  oneYearPerfTD,
  oneMonthPerfTD,
  pBRatioTD
} from './TableData'
import { Dispatch, SetStateAction } from 'react'
import { Spinner } from 'ig-phoenix'
import { columnMap, SortedColumnType, visibleColumnType } from './AppProps'
import LoadMore from './LoadMore'

interface StockTableProps {
  data: any
  columns: visibleColumnType
  colors: string[]
  locale: string
  apiQuery: string
  setApiQuery: Dispatch<SetStateAction<string>>
  setData: Dispatch<SetStateAction<any[] | null>>
  setPage: Dispatch<SetStateAction<number>>
  loading: boolean
  sortedBy: SortedColumnType
  setSortedBy: Dispatch<SetStateAction<SortedColumnType>>
  fetchError: boolean
  emptyData: boolean
  appWidth: number
}

export default function StockTable (props: StockTableProps): JSX.Element {
  const { data, columns, colors, locale, apiQuery, setApiQuery, setData, setPage, loading, sortedBy, setSortedBy, emptyData, fetchError, appWidth } = props

  const [bgColor, colShort, colLong, colPrimary, circleTextCol] = colors

  const handleSort = (name: string) => {
    if (name === 'Price Chart (7D)' || name === 'Volume' || name === '52-Week Range') {
      return
    }
    setData([])
    setPage(1)
    const temp = { ...sortedBy }
    temp.columnId = name === sortedBy.column ? temp.columnId : columnMap[name]
    temp.direction = name === sortedBy.column ? (sortedBy.direction === 2 ? 1 : 2) : 2
    temp.column = name
    setApiQuery(apiQuery.replace(`sortBy=${sortedBy.columnId}&sortDir=${sortedBy.direction}`, `sortBy=${temp.columnId}&sortDir=${temp.direction}`))
    setSortedBy(temp)
  }

  return (
    <>
      <TableComponent isLoading={loading} stockCount={data.length} data-testid='stock-screener-table'>
        <thead>
          <THeading data-testid='stock-screener-table-header-row'>
            <TH
              bgColor={bgColor}
              key='name'
              onClick={() => handleSort('Name')}
              sortedColumn={sortedBy.column}
              name={'Name'}
              data-testid={'Name-heading'}
            >
              <Heading name='Name' sortedColumn={sortedBy} width={appWidth > 400 ? '200px' : '125px'}/>
            </TH>
            {Object.keys(columns).map(
              c => columns[c].visible &&
                <TH
                  bgColor={bgColor}
                  key={c}
                  onClick={() => handleSort(c)}
                  sortedColumn={sortedBy.column}
                  name={c}
                  data-testid={`${c}-heading`}
                >
                  <Heading name={c} sortedColumn={sortedBy} width={columns[c].width}/>
                </TH>)}
          </THeading>
        </thead>
        <tbody>
          {/* TODO update when endpoints are set up */}
          {/* @ts-expect-error */}
          {data.map((data, i) =>
            <tr data-testid={`${data.tradingInformationData.stockListingTicker}-row`} key ={`${data.tradingInformationData.stockListingTicker}-${i}`}>
              {marketNameTD(data.tradingInformationData, bgColor, appWidth)}
              {columns.Price.visible && priceTD(data.tradingInformationData, locale, '80px')}
              {columns.Sector.visible && sectorTD(data.tradingInformationData, '140px')}
              {columns['Market Cap'].visible && marketCapTD(data.tradingInformationData, locale, '80px')}
              {columns['Smart Score'].visible && smartScoreTD(data.tipRanksEssentialData, colShort, colLong, colPrimary, circleTextCol)}
              {columns['Dividend Yield'].visible && dividendYieldTD(data.dividendData, data.tradingInformationData, locale, '130px')}
              {columns['Analyst Price Target'].visible && analystPriceTargetTD(data.tipRanksEssentialData, data.tradingInformationData, locale, '120px')}
              {columns.Volume.visible && volumeTD(data.dividendData, locale, '80px')}
              {columns['Average 3 Month Volume'].visible && avg3MVolumeTD(data.tradingInformationData, locale, '100px')}
              {columns['PE Ratio'].visible && peRatioTD(data.tradingInformationData, '80px')}
              {columns['Upcoming Earnings Date'].visible && earningsDateTD(data.earningsData, locale, '100px')}
              {columns['Analyst Consensus'].visible && analystConsensusTD(data.tipRanksEssentialData, '100px')}
              {columns['News Sentiment'].visible && newsSentimentTD(data.tipRanksEssentialData, '100px')}
              {columns['Media Buzz'].visible && mediaBuzzTD(data.tipRanksEssentialData, '80px')}
              {/* {columns['Price Chart (7D)'] && priceChartTD()}TODO price chart 7D */}
              {columns['Best Analyst Consensus'].visible && bestAnalystConsensusTD(data.tipRanksEssentialData, '100px')}
              {columns['Best Analyst Price Target'].visible && bestAnalystPriceTargetTD(data.tipRanksEssentialData, data.tradingInformationData, locale, '120px')}
              {columns['Insider Signal'].visible && insiderSignalTD(data.tipRanksEssentialData, '100px')}
              {columns['Blogger Consensus'].visible && bloggerConsensusTD(data.tipRanksEssentialData, '80px')}
              {columns['Investor Sentiment'].visible && investorSentimentTD(data.tipRanksEssentialData, '100px')}
              {columns['Hedge Fund Signal'].visible && hedgeFundSignalTD(data.tipRanksEssentialData, '80px')}
              {columns['52-Week Range'].visible && fiftyTwoWeekTD(data.tradingInformationData, locale, '250px')}
              {columns['Ex-Dividend Date'].visible && exDivDateTD(data.dividendData, locale, '80px')}
              {columns['Price/Book Ratio'].visible && pBRatioTD(data.technicalsData, locale, '80px')}
              {columns['Price/Sales Ratio'].visible && pSRatioTD(data.technicalsData, locale, '80px')}
              {columns['Price/FCF Ratio'].visible && pFCFRatioTD(data.technicalsData, locale, '60px')}
              {columns['1 Day %'].visible && oneDayPerfTD(data.tradingInformationData, '70px')}
              {columns['1 Month %'].visible && oneMonthPerfTD(data.performanceData, '80px')}
              {columns['1 Year %'].visible && oneYearPerfTD(data.performanceData, '70px')}
            </tr>
          )}
        </tbody>
        {!loading && !fetchError && !emptyData &&
        <tbody>
          <tr><td style={{ position: 'sticky', left: '0', paddingLeft: '10px' }}>
            <LoadMore setPage={setPage}/>
          </td>
          </tr>
        </tbody>}
        {loading &&
        <tbody>
          <tr>
            <td style={{ position: 'sticky', left: '0' }}>
              <SpinnerContainer data-testid='stockTableIsLoading'><Spinner radius='40px'/></SpinnerContainer>
            </td>
          </tr>
        </tbody>}
      </TableComponent>
    </>
  )
}

const TableComponent = styled.table<{
  isLoading: boolean
  stockCount: number
}>`
  display: block;
  overflow-x: auto;
  border-spacing: 0;
  border-collapse: unset;
  height: ${({ isLoading, stockCount }) => isLoading && stockCount === 0 ? '100px' : '570px'};
`

const TH = styled.th<{
  bgColor: string
  sortedColumn: string
  name: string
}>`
  position: sticky;
  z-index: 4; 
  top: 0;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  padding-top: 17px;
  border-bottom: ${({ sortedColumn, name }) => name === sortedColumn ? ' 2px solid #e01b1c' : ''};

  &:first-child {
    z-index: 5;
    left: 0;
    text-align: start;
    background-color: ${({ sortedColumn, name, theme, bgColor }) => name === sortedColumn ? theme.color.separator.primary.background.value : bgColor};
    box-shadow: ${({ bgColor, theme }) => bgColor === theme.color.background.primarySurface.value ? '4px 0px 4px 0px #0000000D' : '4px 0px 4px 0px #00000080'};
  }
`

const THeading = styled.tr`
  vertical-align: top;
  text-transform: uppercase;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
`
