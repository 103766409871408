import { TriangleArrowDown, TriangleArrowUp } from '@ig-caa/media'
import styled from 'styled-components'
import { SortedColumnType } from './AppProps'

export default function Heading ({ name, sortedColumn, width }: { name: string, sortedColumn: SortedColumnType, width: string }): JSX.Element {
  return (
    <TableHeading justify={name === 'Name' ? 'flex-start' : name === '52-Week Range' ? 'center' : 'flex-end'} width={width}>
      <HeadingText>{name}</HeadingText>
      { name === 'Price Chart (7D)' || name === 'Volume' || name === '52-Week Range'
        ? ''
        : sortedColumn.column === name
          ? (
              sortedColumn.direction === 2
                ? (
                    <ArrowContainers data-testid={`${name}-descending-arrow`}>
                      <TriangleArrowDown color="#A8A8A8" />
                    </ArrowContainers>
                  )
                : (
                    <ArrowContainers data-testid={`${name}-ascending-arrow`}>
                      <TriangleArrowUp color="#A8A8A8" />
                    </ArrowContainers>
                  )
            )
          : (
              <ArrowContainers data-testid={`${name}-both-arrows`}>
                <TriangleArrowUp color="#A8A8A8" />
                <TriangleArrowDown color="#A8A8A8" />
              </ArrowContainers>
            )
      }
    </TableHeading>
  )
}

const HeadingText = styled.div`
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  text-align: right;
  color: ${({ theme }) => theme.color.text.tertiary.value};
  padding-right: 4px;
`

const ArrowContainers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const TableHeading = styled.div<{
  justify: string
  width: string
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width};
`
