import { MappingType } from '../components/AppProps'

export const consensus: MappingType = {
  StrongBuy: 'Strong Buy',
  Buy: 'Moderate Buy',
  Neutral: 'Hold',
  Sell: 'Moderate Sell',
  StrongSell: 'Strong Sell'
}

export const newsSentiment: MappingType = {
  StrongBuy: 'Very Bullish',
  Buy: 'Bullish',
  Neutral: 'Neutral',
  Sell: 'Bearish',
  StrongSell: 'Very Bearish'
}

export const mediaBuzz: MappingType = {
  0: 'Very Low',
  1: 'Very Low',
  2: 'Very Low',
  3: 'Low',
  4: 'Low',
  5: 'Medium',
  6: 'Medium',
  7: 'High',
  8: 'High',
  9: 'Very High',
  10: 'Very High'
}

export const signal: MappingType = {
  Buy: 'Positive',
  Hold: 'Neutral',
  Sell: 'Negative'
}

export const investorSentiment: MappingType = {
  5: 'Very Positive',
  4: 'Positive',
  3: 'Neutral',
  2: 'Negative',
  1: 'Very Negative'
}
