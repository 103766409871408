export interface ColumnFilterType {
  categoryName: string
  categoryValue: string
  checked: boolean
}

export interface FilterByColumnStateObjType {
  [key: string]: {
    displayName: string
    filters: ColumnFilterType[]
    keyName: string
  }
}

export const filterStateObj: FilterByColumnStateObjType = {
  analystPriceTarget: {
    displayName: 'Price Target Upside',
    keyName: 'analystPriceTarget',
    filters: [
      { categoryName: 'Upside > 20%', categoryValue: 'Above20', checked: false },
      { categoryName: 'Upside 10% - 20%', categoryValue: 'Above10', checked: false },
      { categoryName: 'Upside 5% - 10%', categoryValue: 'Above5', checked: false },
      { categoryName: 'Upside 0% - 5%', categoryValue: 'Above0', checked: false },
      { categoryName: 'Downside 0% - 5%', categoryValue: 'AboveMinus5', checked: false },
      { categoryName: 'Downside 5% - 10%', categoryValue: 'AboveMinus10', checked: false },
      { categoryName: 'Downside 10% - 20%', categoryValue: 'AboveMinus20', checked: false },
      { categoryName: 'Downside > 20%', categoryValue: 'BelowMinus20', checked: false }
    ]
  },
  dividendYield: {
    displayName: 'Dividend Yield',
    keyName: 'dividendYield',
    filters: [
      { categoryName: 'Very High (>5%)', categoryValue: 'VeryHigh', checked: false },
      { categoryName: 'High (2.5% -5%)', categoryValue: 'High', checked: false },
      { categoryName: 'Low (0% - 2.5%)', categoryValue: 'Positive', checked: false },
      { categoryName: 'None (0%)', categoryValue: 'None', checked: false }
    ]
  },
  sector: {
    displayName: 'Sector',
    keyName: 'sector',
    filters: [
      { categoryName: 'Financial', categoryValue: 'Financial', checked: false },
      { categoryName: 'Healthcare', categoryValue: 'Healthcare', checked: false },
      { categoryName: 'Communication Services', categoryValue: 'CommunicationServices', checked: false },
      { categoryName: 'Utilities', categoryValue: 'Utilities', checked: false },
      { categoryName: 'Basic Materials', categoryValue: 'Materials', checked: false },
      { categoryName: 'Technology', categoryValue: 'Technology', checked: false },
      { categoryName: 'Industrials', categoryValue: 'Industrials', checked: false },
      { categoryName: 'General', categoryValue: 'General', checked: false },
      { categoryName: 'Energy', categoryValue: 'Energy', checked: false },
      { categoryName: 'Real Estate', categoryValue: 'RealEstate', checked: false },
      { categoryName: 'Consumer Defensive', categoryValue: 'ConsumerDefensive', checked: false },
      { categoryName: 'Consumer Cyclical', categoryValue: 'ConsumerCyclical', checked: false }

    ]
  },
  analystConsensus: {
    displayName: 'Analyst Consensus',
    keyName: 'analystConsensus',
    filters: [
      { categoryName: 'Strong Buy', categoryValue: 'StrongBuy', checked: false },
      { categoryName: 'Moderate Buy', categoryValue: 'Buy', checked: false },
      { categoryName: 'Hold', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Moderate Sell', categoryValue: 'Sell', checked: false },
      { categoryName: 'Strong Sell', categoryValue: 'StrongSell', checked: false }
    ]
  },
  investorSentiment: {
    displayName: 'Investor Sentiment',
    keyName: 'investorSentiment',
    filters: [
      { categoryName: 'Very Positive', categoryValue: 'StrongBuy', checked: false },
      { categoryName: 'Positive', categoryValue: 'Buy', checked: false },
      { categoryName: 'Neutral', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Negative', categoryValue: 'Sell', checked: false },
      { categoryName: 'Very Negative', categoryValue: 'StrongSell', checked: false }
    ]
  },
  newBloggerConsensus: {
    displayName: 'Blogger Consensus',
    keyName: 'newBloggerConsensus',
    filters: [
      { categoryName: 'Bullish', categoryValue: 'Bullish', checked: false },
      { categoryName: 'Neutral', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Bearish', categoryValue: 'Bearish', checked: false }
    ]
  },
  newsSentiment: {
    displayName: 'News Sentiment',
    keyName: 'newsSentiment',
    filters: [
      { categoryName: 'Very Bullish', categoryValue: 'StrongBuy', checked: false },
      { categoryName: 'Bullish', categoryValue: 'Buy', checked: false },
      { categoryName: 'Neutral', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Bearish', categoryValue: 'Sell', checked: false },
      { categoryName: 'Very Bearish', categoryValue: 'StrongSell', checked: false }
    ]
  },
  buzz: {
    displayName: 'Media Buzz',
    keyName: 'buzz',
    filters: [
      { categoryName: 'Very High', categoryValue: 'VeryHigh', checked: false },
      { categoryName: 'High', categoryValue: 'High', checked: false },
      { categoryName: 'Medium', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Low', categoryValue: 'Low', checked: false },
      { categoryName: 'Very Low', categoryValue: 'VeryLow', checked: false }
    ]
  },
  insiderSignal: {
    displayName: 'Insider Signal',
    keyName: 'insiderSignal',
    filters: [
      { categoryName: 'Positive', categoryValue: 'Buy', checked: false },
      { categoryName: 'Neutral', categoryValue: 'Hold', checked: false },
      { categoryName: 'Negative', categoryValue: 'Sell', checked: false }
    ]
  },
  hedgeFundSignal: {
    displayName: 'Hedge Fund Signal',
    keyName: 'hedgeFundSignal',
    filters: [
      { categoryName: 'Positive', categoryValue: 'Buy', checked: false },
      { categoryName: 'Neutral', categoryValue: 'Hold', checked: false },
      { categoryName: 'Negative', categoryValue: 'Sell', checked: false }
    ]
  },
  bestAnalystConsensus: {
    displayName: 'Best Analyst Consensus',
    keyName: 'bestAnalystConsensus',
    filters: [
      { categoryName: 'Strong Buy', categoryValue: 'StrongBuy', checked: false },
      { categoryName: 'Moderate Buy', categoryValue: 'Buy', checked: false },
      { categoryName: 'Hold', categoryValue: 'Neutral', checked: false },
      { categoryName: 'Moderate Sell', categoryValue: 'Sell', checked: false },
      { categoryName: 'Strong Sell', categoryValue: 'StrongSell', checked: false }
    ]
  },
  bestPriceTarget: {
    displayName: 'Top Analysts\' Price Target',
    keyName: 'bestPriceTarget',
    filters: [
      { categoryName: 'Upside > 20%', categoryValue: 'Above20', checked: false },
      { categoryName: 'Upside 10% - 20%', categoryValue: 'Above10', checked: false },
      { categoryName: 'Upside 5% - 10%', categoryValue: 'Above5', checked: false },
      { categoryName: 'Upside 0% - 5%', categoryValue: 'Above0', checked: false },
      { categoryName: 'Downside 0% - 5%', categoryValue: 'AboveMinus5', checked: false },
      { categoryName: 'Downside 5% - 10%', categoryValue: 'AboveMinus10', checked: false },
      { categoryName: 'Downside 10% - 20%', categoryValue: 'AboveMinus20', checked: false },
      { categoryName: 'Downside > 20%', categoryValue: 'BelowMinus20', checked: false }
    ]
  },
  tipRanksScore: {
    displayName: 'Smart Score',
    keyName: 'tipRanksScore',
    filters: [
      { categoryName: '10 (Outperform)', categoryValue: 'StrongBuy', checked: false },
      { categoryName: '8-9 (Outperform)', categoryValue: 'Buy', checked: false },
      { categoryName: '4-7 (Neutral)', categoryValue: 'Neutral', checked: false },
      { categoryName: '2-3 (Underperform)', categoryValue: 'Sell', checked: false },
      { categoryName: '1 (Underperform)', categoryValue: 'StrongSell', checked: false }
    ]
  },
  fiftyTwoWeekRange: {
    displayName: '52-Week Range',
    keyName: 'fiftyTwoWeekRange',
    filters: [
      { categoryName: '0-3% Below High', categoryValue: 'BetweenZeroToThreePercentBelowHigh', checked: false },
      { categoryName: '3%-5% Below High', categoryValue: 'BetweenThreeToFivePercentBelowHigh', checked: false },
      { categoryName: '5%-10% Below High', categoryValue: 'BetweenFiveToTenPercentBelowHigh', checked: false },
      { categoryName: '5%-10% Above Low', categoryValue: 'BetweenFiveToTenPercentBelowLow', checked: false },
      { categoryName: '3%-5% Above Low', categoryValue: 'BetweenThreeToFivePercentBelowLow', checked: false },
      { categoryName: '0-3% Above Low', categoryValue: 'BetweenZeroToThreePercentAboveLow', checked: false }
    ]
  },
  earningsDate: {
    displayName: 'Earnings Date',
    keyName: 'earningsDate',
    filters: [
      { categoryName: 'Today', categoryValue: 'Today', checked: false },
      { categoryName: 'Tomorrow', categoryValue: 'Tomorrow', checked: false },
      { categoryName: 'Next 5 Days', categoryValue: 'NextFiveDays', checked: false },
      { categoryName: 'This Week', categoryValue: 'CurrentWeek', checked: false },
      { categoryName: 'Next Week', categoryValue: 'NextWeek', checked: false },
      { categoryName: 'This Month', categoryValue: 'CurrentMonth', checked: false }
    ]
  },
  dividendDate: {
    displayName: 'Ex-Dividend Date',
    keyName: 'dividendDate',
    filters: [
      { categoryName: 'Today', categoryValue: 'Today', checked: false },
      { categoryName: 'In One Week', categoryValue: 'OneWeek', checked: false },
      { categoryName: 'In Two Weeks', categoryValue: 'TwoWeeks', checked: false },
      { categoryName: 'In One Month', categoryValue: 'OneMonth', checked: false }
    ]
  },
  priceToSalesRatio: {
    displayName: 'Price/Sales Ratio',
    keyName: 'priceToSalesRatio',
    filters: [
      { categoryName: '0-1', categoryValue: 'Between0To1', checked: false },
      { categoryName: '1-3', categoryValue: 'Between1To3', checked: false },
      { categoryName: '3-5', categoryValue: 'Between3To5', checked: false },
      { categoryName: '5-10', categoryValue: 'Between5To10', checked: false },
      { categoryName: '>10', categoryValue: 'Above10', checked: false }
    ]
  }
}
