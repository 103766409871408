import { useTheme } from 'styled-components'
import React from 'react'

export default function ResetIcon (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.negativeAmount.value
  return (
    <svg width="14" height="14" viewBox="0 0 15 15" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7633 4.23317C10.7966 3.2665 9.46992 2.6665 7.99659 2.6665C5.04992 2.6665 2.66992 5.05317 2.66992 7.99984C2.66992 10.9465 5.04992 13.3332 7.99659 13.3332C10.4833 13.3332 12.5566 11.6332 13.1499 9.33317H11.7633C11.2166 10.8865 9.73659 11.9998 7.99659 11.9998C5.78992 11.9998 3.99659 10.2065 3.99659 7.99984C3.99659 5.79317 5.78992 3.99984 7.99659 3.99984C9.10326 3.99984 10.0899 4.45984 10.8099 5.1865L8.66326 7.33317H13.3299V2.6665L11.7633 4.23317Z" fill={svgColour}/>
    </svg>
  )
}