import { FlagIconCode } from 'react-flag-kit'

export const enum Theme {
  Light = 'Light',
  Dark = 'Dark'
}

export enum ENVIRONMENTS {
  test = 'test',
  uat = 'uat',
  demo = 'demo',
  prod = 'prod'
}

export interface AppProps {
  theme: Theme
  cst: string
  sessionData: {
    clientLocale: string
    webSiteId: string
  }
  env: ENVIRONMENTS
  initialWidth: number
}

/* Reusable type for string mapping */
export interface MappingType {
  [key: string]: string
}

/* Object containing columns and key names mapping for sortable columns */
export const columnMap: MappingType = {
  Name: 'Name',
  Price: 'Price',
  Sector: 'Sector',
  'Market Cap': 'MarketCap',
  'Dividend Yield': 'DividendYield',
  'Analyst Price Target': 'PriceTarget',
  'Average 3 Month Volume': 'Average3MVolume',
  'PE Ratio': 'PeRatio',
  'Analyst Consensus': 'AnalystConsensus',
  'News Sentiment': 'NewsSentiment',
  'Media Buzz': 'Buzz',
  'Best Analyst Consensus': 'BestAnalystConsensus',
  'Best Analyst Price Target': 'BestPriceTarget',
  'Insider Signal': 'InsiderSignal',
  'Blogger Consensus': 'BloggerConsensus',
  'Investor Sentiment': 'InvestorScore',
  'Hedge Fund Signal': 'HedgeFundSignal',
  'Smart Score': 'TipRanksScore',
  'Upcoming Earnings Date': 'UpcomingEarningsDate',
  'Ex-Dividend Date': 'DividendDate',
  'Price/Book Ratio': 'PtbRatio',
  'Price/Sales Ratio': 'PriceToSalesRatio',
  'Price/FCF Ratio': 'PfcfRatio',
  '1 Day %': 'PriceChangePercent',
  '1 Month %': 'OneMonthChangePercent',
  '1 Year %': 'YearlyGain'
}

/* Structure of a sorted column
  column: name of the column as displayed on page
  columnId: keyName of the column for sorting *see columnMap*
  direction: direction of sort, 2 for descending and 1 for ascending
*/
export interface SortedColumnType {
  column: string
  columnId: string
  direction: number
}

/* Object containing the default sorted column */
export const defaultSortedColumn: SortedColumnType = {
  column: 'Market Cap',
  columnId: 'MarketCap',
  direction: 2
}

/* Structure to determine a visible column based on boolean value */
export interface visibleColumnType {
  [key: string]: { width: string, visible: boolean }
}

/* Object containing default columns with their widths (for headings
   to be 20px more than individual corresponding <td> elements because
   of padding applied to <td>) and boolean values on page load */
export const defaultColumnObj: visibleColumnType = {
  Price: { width: '100px', visible: true },
  Sector: { width: '160px', visible: true },
  'Market Cap': { width: '100px', visible: true },
  'Smart Score': { width: '100px', visible: true },
  'Dividend Yield': { width: '150px', visible: true },
  'Analyst Price Target': { width: '140px', visible: true },
  Volume: { width: '100px', visible: true },
  'Average 3 Month Volume': { width: '120px', visible: true },
  'PE Ratio': { width: '100px', visible: true },
  'Upcoming Earnings Date': { width: '120px', visible: false },
  'Analyst Consensus': { width: '120px', visible: false },
  'News Sentiment': { width: '120px', visible: false },
  'Media Buzz': { width: '100px', visible: false },
  // 'Price Chart (7D)': false,
  'Best Analyst Consensus': { width: '120px', visible: false },
  'Best Analyst Price Target': { width: '140px', visible: false },
  'Insider Signal': { width: '120px', visible: false },
  'Blogger Consensus': { width: '100px', visible: false },
  'Investor Sentiment': { width: '120px', visible: false },
  'Hedge Fund Signal': { width: '100px', visible: false },
  '52-Week Range': { width: '270px', visible: false },
  'Ex-Dividend Date': { width: '100px', visible: false },
  'Price/Book Ratio': { width: '100px', visible: false },
  'Price/Sales Ratio': { width: '100px', visible: false },
  'Price/FCF Ratio': { width: '80px', visible: false },
  '1 Day %': { width: '90px', visible: false },
  '1 Month %': { width: '100px', visible: false },
  '1 Year %': { width: '90px', visible: false }
}

export interface CountryFilterType {
  [key: string]: { countryFlag: FlagIconCode, apiString: string, countryName: string, checked: boolean }
}

/* Objects containing the default markets for both isa accounts and share dealing accounts */
export const defaultCountryFilters: CountryFilterType = {
  US: { countryFlag: 'US', apiString: '&country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats', countryName: 'United States', checked: true },
  CA: { countryFlag: 'CA', apiString: '&country=canada', countryName: 'Canada', checked: true },
  GB: { countryFlag: 'GB', apiString: '&country=uk', countryName: 'United Kingdom', checked: true },
  DE: { countryFlag: 'DE', apiString: '&country=germany', countryName: 'Germany', checked: true },
  ES: { countryFlag: 'ES', apiString: '&country=spain', countryName: 'Spain', checked: true },
  IT: { countryFlag: 'IT', apiString: '&country=italy', countryName: 'Italy', checked: true },
  FR: { countryFlag: 'FR', apiString: '&country=france', countryName: 'France', checked: true },
  AU: { countryFlag: 'AU', apiString: '&country=australia', countryName: 'Australia', checked: true }
}

export const isaCountryFilters: CountryFilterType = {
  US: { countryFlag: 'US', apiString: '&country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats', countryName: 'United States', checked: true },
  GB: { countryFlag: 'GB', apiString: '&country=uk', countryName: 'United Kingdom', checked: true },
  AU: { countryFlag: 'AU', apiString: '&country=australia', countryName: 'Australia', checked: true }
}
