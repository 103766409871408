import styled, { useTheme } from 'styled-components'
import { HideIcon, ResetIcon, ShowIcon, TipRankLogo } from '@ig-caa/media'
import StockTable from './StockTable'
import { FilterByColumnStateObjType, filterStateObj } from './filter-components/FilterByColumnStateObj'
import FilterByColumn from './filter-components/FilterByColumn'
import { useEffect, useRef, useState } from 'react'
import FilterByMarket from './filter-components/FilterByMarket'
import ColumnDisplayFilter from './filter-components/ColumnDisplayFilter'
import { defaultColumnObj, defaultSortedColumn, AppProps, defaultCountryFilters, isaCountryFilters } from './AppProps'
import { useTranslation } from '@ig-caa/i18n'
import FilteredColumnCards from './filter-components/FilteredColumnCards'
import { Spinner } from 'ig-phoenix'
import fetchStockScreener from '../utils/fetchStockScreener'

export default function StockScreener (props: AppProps) {
  const locale = props.sessionData.clientLocale
  const webSiteId = props.sessionData.webSiteId
  const { env, theme, cst, initialWidth } = props

  const { t } = useTranslation()
  const phoenixTheme = useTheme()
  /* istanbul ignore next */
  const bgColor = (theme === 'Light') ? phoenixTheme.color.background.primarySurface.value : phoenixTheme.color.background.primary.value
  /* istanbul ignore next */
  const appTextCol = (theme === 'Light') ? 'black' : 'white'
  const colShort = phoenixTheme.color.text.short.value
  const colLong = phoenixTheme.color.text.long.value
  const colPrimary = phoenixTheme.color.text.primary.value
  const colPrimaryInverted = phoenixTheme.color.text.inverted.value
  /* istanbul ignore next */
  const circleTextCol = (theme === 'Light') ? colPrimaryInverted : colPrimary

  const colors = [bgColor, colShort, colLong, colPrimary, circleTextCol]
  const shareWebsiteId = ['igs', 'isa', 'aus', 'dbs']

  const localStorageRetriever = (key: string, appState: any) => {
    const localStorageObj = JSON.parse((localStorage.getItem(key) || 'null'))
    if (!localStorageObj) {
      return appState
    }
    Object.keys(localStorageObj).forEach((key) => {
      /* istanbul ignore if */
      if (!(key in appState)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete localStorageObj[key]
      }
    })
    return { ...appState, ...localStorageObj }
  }

  /* istanbul ignore next */
  const localApiRetriever = (key: string, webSiteId: string) => {
    const localStorageApiQuery = localStorage.getItem(key)

    if (!localStorageApiQuery) {
      return shareWebsiteId.includes(webSiteId)
        ? 'sortBy=MarketCap&sortDir=2&isPrimaryMarket=true&country=uk&country=australia&country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats'
        : 'sortBy=MarketCap&sortDir=2&isPrimaryMarket=true&country=uk&country=australia&country=spain&country=canada&country=germany&country=italy&country=france' +
        '&country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats'
    }
    return localStorageApiQuery
  }

  const [activeFilters, setActiveFilters] = useState<FilterByColumnStateObjType>(localStorageRetriever(`stockScreener_activeFilters-${webSiteId}-1`, structuredClone(filterStateObj)))
  const [visibleColumns, setVisibleColumns] = useState(localStorageRetriever('stockScreener_visibleColumns-1', defaultColumnObj))
  const [data, setData] = useState<any[] | null>(null)
  const [totalStocks, setTotalStocks] = useState(0)
  const [page, setPage] = useState(1)
  const [apiQuery, setApiQuery] = useState<string>(localApiRetriever(`stockScreener_apiQuery-${webSiteId}-1`, webSiteId))
  const [loading, setLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [emptyData, setEmptyData] = useState(false)
  const [sortedBy, setSortedBy] = useState(JSON.parse(localStorage.getItem(`stockScreener_sortedColumn-${webSiteId}-1`) || 'null') ?? defaultSortedColumn)
  const [markets, setMarkets] = useState(localStorageRetriever(`stockScreener_markets-${webSiteId}-1`,
    (shareWebsiteId.includes(webSiteId) ? isaCountryFilters : defaultCountryFilters)))
  const [activeFilterCount, setActiveFilterCount] = useState(parseInt(localStorage.getItem(`stockScreener_activeFilterCount-${webSiteId}-1`) || '0'))

  const resetFilters = () => {
    /* istanbul ignore next */
    if (activeFilterCount === 0) return
    setActiveFilters(structuredClone(filterStateObj))
    shareWebsiteId.includes(webSiteId)
      ? setApiQuery(`sortBy=${sortedBy.columnId}&sortDir=${sortedBy.direction}
        &isPrimaryMarket=true&country=uk&country=australia&country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats`)
      : setApiQuery(`sortBy=${sortedBy.columnId}&sortDir=${sortedBy.direction}
        &isPrimaryMarket=true&country=uk&country=australia&country=spain&country=canada&country=germany&country=italy&country=france
        &country=us&exchange=xnas&exchange=xnys&exchange=arcx&exchange=xase&exchange=bats`)
    setData(null)
    setPage(1)
    setActiveFilterCount(0)
    setMarkets(shareWebsiteId.includes(webSiteId) ? isaCountryFilters : defaultCountryFilters)
  }

  useEffect(() => {
    localStorage.setItem(`stockScreener_apiQuery-${webSiteId}-1`, apiQuery)
    localStorage.setItem(`stockScreener_activeFilterCount-${webSiteId}-1`, JSON.stringify(activeFilterCount))
    localStorage.setItem('stockScreener_visibleColumns-1', JSON.stringify(visibleColumns))
    localStorage.setItem(`stockScreener_sortedColumn-${webSiteId}-1`, JSON.stringify(sortedBy))
    localStorage.setItem(`stockScreener_activeFilters-${webSiteId}-1`, JSON.stringify(activeFilters))
    localStorage.setItem(`stockScreener_markets-${webSiteId}-1`, JSON.stringify(markets))
  }, [apiQuery, visibleColumns, sortedBy, activeFilters, markets, activeFilterCount])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    setLoading(true)
    setFetchError(false)
    setEmptyData(false)
    fetchStockScreener(env, cst, apiQuery, page, signal)
      .then(res => {
        if (!res.ok) throw new Error()
        return res.json()
      })
      .then(res => {
        if (res.totalCount === 0 || !apiQuery.includes('country')) {
          setEmptyData(true)
        }
        setData((prevState) => {
          if (prevState === null) {
            return res.stocksScreenerData
          }
          return [...prevState, ...res.stocksScreenerData]
        })
        setLoading(false)
        setTotalStocks(!apiQuery.includes('country') ? 0 : res.totalCount)
      }).catch((e) => {
        if (e.name !== 'AbortError') {
          setFetchError(true)
        }
      })

    return () => {
      controller.abort()
    }
  }, [apiQuery, page])

  const appContainer = useRef(null)
  const [appWidth, setAppWidth] = useState(initialWidth ?? 1150)
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    const element = appContainer.current
    /* istanbul ignore next */
    if (!element) return

    let animationFrameId: number | null = null

    /* istanbul ignore next */
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const newWidth = entries[0].contentRect.width

      // Use requestAnimationFrame to debounce updates
      if (animationFrameId) cancelAnimationFrame(animationFrameId)

      animationFrameId = requestAnimationFrame(() => {
        setAppWidth((prevWidth) => {
          if (prevWidth !== newWidth) {
            return newWidth
          }
          return prevWidth
        })
      })
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(element)

    // Clean up the observer on component unmount
    return () => {
      resizeObserver.disconnect()
    }
  }, [appContainer.current])

  return (
    <AppContainer data-testid='stock-screener-container' bgColor={bgColor} appTextCol={appTextCol} ref={appContainer}>
      <TopRow appWidth={appWidth}>
        {(appWidth <= 400 && showFilters) &&
          <ShowHideFiltersButton data-testid='hide-filters-button' onClick={() => setShowFilters(false)}>
            <HideIcon/> Hide Filters
          </ShowHideFiltersButton>}
        {(appWidth > 400 || showFilters) && Object.values(activeFilters).map((filter, i) =>
          <FilterByColumn
            key={i}
            filter={filter}
            apiQuery={apiQuery}
            setApiQuery={setApiQuery}
            setData={setData}
            setPage={setPage}
            theme={theme}
            activeFilters={activeFilters}
            appWidth={appWidth}
            setActiveFilterCount={setActiveFilterCount} />
        )}
        {(appWidth <= 400 && !showFilters) &&
        <ShowHideFiltersButton data-testid='show-filters-button' onClick={() => setShowFilters(true)}>
          <ShowIcon/> Show Filters
        </ShowHideFiltersButton>}
      </TopRow>
      {(appWidth > 400 || showFilters) && <FilteredColumnCards
        filter={activeFilters}
        setApiQuery={setApiQuery}
        apiQuery={apiQuery} setData={setData}
        setPage={setPage}
        setActiveFilterCount={setActiveFilterCount}
      />}
      {(appWidth > 400 || showFilters) && (activeFilterCount > 0) &&
        <ResetFiltersContainer onClick={resetFilters}>
          <ResetFiltersText data-testid='reset-filters-button'>Reset Filters</ResetFiltersText><ResetIcon/>
        </ResetFiltersContainer>}
      <Divider/>
      <MiddleRow>
        <MiddleRowLeft>
          <ColumnDisplayFilter visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} theme={theme}/>
          <FilterByMarket setApiQuery={setApiQuery} apiQuery={apiQuery} setData={setData} setPage={setPage} markets={markets} setMarkets={setMarkets} theme={theme}/>
          <div><b>{totalStocks}</b> {totalStocks === 1 ? 'result' : 'results'}</div>
        </MiddleRowLeft>
        {appWidth > 400 &&
          <MiddleRowRight>
            <TipRankLogo/>
          </MiddleRowRight>
        }
      </MiddleRow>
      <Divider/>
      {loading && data === null && !fetchError && !emptyData && <SpinnerContainer data-testid='stockTableIsLoading'><Spinner radius='40px'/></SpinnerContainer>}
      {fetchError && <ErrorText data-testid="fetch-error">We&apos;re not able to load this data right now.<br/>Please come back later.</ErrorText>}
      {emptyData && <ErrorText data-testid="no-data-error">There are no shares that match this filter</ErrorText>}
      {data && !fetchError && !emptyData &&
        <StockTable
          data={data}
          columns={visibleColumns}
          colors={colors}
          locale={locale}
          apiQuery={apiQuery}
          setApiQuery={setApiQuery}
          setData={setData}
          setPage={setPage}
          loading={loading}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          fetchError={fetchError}
          emptyData={emptyData}
          appWidth={appWidth}
        />
      }
      <Footer bgColor={bgColor} data-testid='stock-screener-disclaimer'>
        <Divider/>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <FooterText>{t('disclaimer')}</FooterText>
          {appWidth <= 400 && <div data-testid='bottom-logo'><TipRankLogo/></div>}
        </div>
      </Footer>
    </AppContainer>
  )
}

const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MiddleRowLeft = styled.div`
  display: flex;
  gap: 6px;
  padding-top: 17px;
  align-items: center;
`
const MiddleRowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
`

const AppContainer = styled.div<{
  bgColor: string
  appTextCol: string
}>`
  padding: 15px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ appTextCol }) => appTextCol};
`

const TopRow = styled.div<{
  appWidth: number
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ appWidth }) => appWidth > 375 ? '12px 6px' : '6px'};
  width: ${({ appWidth }) => appWidth > 375 ? '' : '100%'};
  justify-content: ${({ appWidth }) => appWidth > 400 ? '' : 'space-between'};
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.separator.secondary.background.value};
  margin: 17px 0 0px 0px;
`

const FooterText = styled.div`
  padding-top: 17px;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  margin-bottom: 4px;
  width: 85%;
  max-height: 100px;
`

const Footer = styled.div<{
  bgColor: string
}>`
  padding: 0 16px 8px 0;
  position: sticky;
  bottom: 0;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  z-index: 12;
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  text-align: center;
  line-height: 16px;
  padding-top: 16px;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
`

const ResetFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 110px;
  cursor: pointer;
  gap: 4px;
  margin-top: 12px;
  border: 1px solid ${({ theme }) => theme.color.text.negativeAmount.value};
  border-radius: 8px;
  padding: 6px 0px 6px 8px;
`

const ResetFiltersText = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.color.text.negativeAmount.value};
`

const ShowHideFiltersButton = styled.div`
  height: 14px;
  width: 100%;
  padding: 9px 12px;
  border: 1px solid #8D8D8D;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  box-sizing: unset;
  gap: 4px;
  align-items: center;
`
